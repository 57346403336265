import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`I hope that summer is treating you well and that you can find some time to spend outside, enjoy the weather, unwind, and worry less about investing. And indeed, the stock markets continued to move up. But of course, the worries continue. Where will the economy go? Is a recession coming? Let’s have a look.`}</p>
    <p>{` `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3lgB/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEggf/aAAgBAQABPyFGjj//2gAMAwEAAgADAAAAEFDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAMUEQUf/aAAgBAQABPxAE8Z8l6iA31T//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "stock market",
            "title": "stock market",
            "src": "/static/ffe8af1b769d72118a2dc2c7329e74e3/e5166/stock-market.jpg",
            "srcSet": ["/static/ffe8af1b769d72118a2dc2c7329e74e3/f93b5/stock-market.jpg 300w", "/static/ffe8af1b769d72118a2dc2c7329e74e3/b4294/stock-market.jpg 600w", "/static/ffe8af1b769d72118a2dc2c7329e74e3/e5166/stock-market.jpg 1200w", "/static/ffe8af1b769d72118a2dc2c7329e74e3/eea4a/stock-market.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`The chart shows how the S&P 500 has been steadily improving over the past 9 months. 30-day implied volatility is extremely low, and even the 12-months volatility has been steadily falling. No market fear in sight.`}</p>
    <p>{` `}<a parentName="p" {...{
        "href": "/dashboard/market-vane"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "969px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABn0lEQVQoz22Ry2oUQRiF+w18AJ9DfA9B8AHcuHCTpa7cunKRlUIEkyzMylVMglmMFwQxcUycEGc0yaTTU32pvk9bXVXd9cmMrsQDHxz44cA5vweOf9U0c4QQXAUBYiZIkgQxmyFESBiGy5vWGuccfd9juwV/cryq0fhhSpxIolhSVvWSNM0Jkgg/EcgsI5IJsZTkWU6eFyjVYozF2Q6wQEvXWbzOKvyzEZPRkLPvR0zHI8TFGJzm/+qBRYgDY4nTkv2TkI2PU2SW4/lJwdZgxOabI9b3j3mx95m11594uvuVzcGInaHP9hfB9sGMncOAtyczzqOIqRQ8e3/OnbVTbj4+5dbqhCjJ8DY+RHh3h1y7/43rK8fceDTm9uqUe88veLh1ycr6Tx68nPBk75JXB4IfgcTqOUrVFIVEVxk0Oe5XTlkUeLGcMzhMeDeUBFcVXd2AUuAWuxjoNPQW3F/ftvSNWrKo3NsO1/VoY6nrGs+0Cl2my2HnVc68aWiUIpYpyliyoiCMYqqqJo5iWmMoq4qiLNHGINMUpRTGmOXHfwNiMwJWBcslgAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rolling returns",
            "title": "rolling returns",
            "src": "/static/0c1e1793dba82b7cd7202aa403e7a69e/561da/rolling-returns.png",
            "srcSet": ["/static/0c1e1793dba82b7cd7202aa403e7a69e/5a46d/rolling-returns.png 300w", "/static/0c1e1793dba82b7cd7202aa403e7a69e/0a47e/rolling-returns.png 600w", "/static/0c1e1793dba82b7cd7202aa403e7a69e/561da/rolling-returns.png 969w"],
            "sizes": "(max-width: 969px) 100vw, 969px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Of course, the S&P 500 is heavily geared toward just a few mega corps, all of them quite tech heavy. As a result, the markets can go up, even when there is very little breadth to it. Therefore, let’s have a look at industry sectors and compare their rolling returns. Here is what sticks out:`}</p>
    <ul>
      <li parentName="ul">{`The S&P 500’s 12-month rolling returns turned positive in June.`}</li>
      <li parentName="ul">{`Information technology marks the best-performing sector, likely driven by AI. And that this is also the sector that `}<a parentName="li" {...{
          "href": "/portfolios/tt-round-robin/"
        }}>{`Round Robin`}</a>{` picked for now.`}</li>
      <li parentName="ul">{`Industrials continue to outperform, signaling continuing demand.`}</li>
      <li parentName="ul">{`Energy, which has outperformed throughout 2022 has fallen back in line with the overall market.`}</li>
      <li parentName="ul">{`Utilities have turned negative in April and have stayed there since. This sector has been resilient throughout 2022, but it seems interest rates are finally having an effect.`}</li>
      <li parentName="ul">{`Real estate marks the worst performing sector of the market. However, after first turning negative in mid-2022, it’s been improving in the past few months.`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG8AD//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQgf/aAAgBAQABPyFKZ0hM/9oADAMBAAIAAwAAABBgz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQACAwAAAAAAAAAAAAAAAAEAESExUf/aAAgBAQABPxAE07bczHWNLtKclIE//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "bond market",
            "title": "bond market",
            "src": "/static/488091e5c26a1af9dda30486fb6dfa67/e5166/bond-market.jpg",
            "srcSet": ["/static/488091e5c26a1af9dda30486fb6dfa67/f93b5/bond-market.jpg 300w", "/static/488091e5c26a1af9dda30486fb6dfa67/b4294/bond-market.jpg 600w", "/static/488091e5c26a1af9dda30486fb6dfa67/e5166/bond-market.jpg 1200w", "/static/488091e5c26a1af9dda30486fb6dfa67/eea4a/bond-market.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a>{` `}</p>
    <p>{`This all sums up to a story of a healthy economy and strong demand. A look at the bond market supports the picture. The overall market has been flat for the past six months, which is good news after the strong decline throughout 2022. The yield for the 13-week T-bill runs about a quarter percent above the Federal Funds Rate, indicating that the market has already priced in another rate hike. And the yield spread between the 13-week T-bill and longer-term T-bonds are slowly closing. However, the yield curve will sure stay inverted for quite some time to come.`}</p>
    <p>{`Now, after today, the picture might look a little different, as Fitch’s downgrade of US debt-rating sinks in. However, a look at `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/United_States_federal_government_credit-rating_downgrades"
      }}>{`Wikipedia shows that this is not the first time this happened`}</a>{`. When S&P downgraded US debt on August 5, 2011, markets reacted violently with the Russell 2000 dropping 8.9% on August 8. However, back then this drop was rather short-lived, with the index first breaching pre-downgrade levels just a week later. Let’s hope history repeats itself here. And if not? Then `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane`}</a>{` should act as an emergency brake and protect us from the worst. Now, let’s get back to summer, shall we?`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It’s an honor to have you on our site. If you like what we do, support our growth by sharing how `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader`}</a>{` adds value for you with your friends.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}</p>
    <p>{`Felix`}<br />{`
--`}<br />{`
Felix Bertram`}<br />{`
Founder of TuringTrader.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      